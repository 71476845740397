/* Write here your custom javascript codes */
$(document).ready(function() {
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

    var countries = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/search/country/%QUERY',
        wildcard: '%QUERY'
      }
    });

    $('.header-inner-right input.typeahead, form#planner input.country').typeahead(null, {
        name: 'countries',
        display: 'name',
        hint: true,
        highlight: true,
        source: countries,
        templates: {
            empty: [
                '<div class="empty-message"><p>We konden geen land vinden met deze naam</p></div>'
            ]
        }
    });

    $('.header-inner-right input.typeahead').on('typeahead:selected', function (e, datum) {
        window.location.href = '/land/vaccinatie/'+slugify(datum.name)+'/'+datum.id
    });

    $('input.country1, input.country2, input.country3, input.country4').typeahead(null, {
        name: 'countries',
        display: 'name',
        hint: true,
        highlight: true,
        source: countries,
        templates: {
            empty: [
                '<div class="empty-message"><p>We konden geen land vinden met deze naam</p></div>'
            ]
        }
    });

    $('input.country1, input.country2, input.country3, input.country4').on('typeahead:selected', function (e, datum) {
        console.log('testtt');
        $(this).parents().eq(2).find('input.country').attr('value', datum.id);
    });

    $('a.delete-vaccination').on('click',function(){
      if (confirm("Weet je zeker dat je deze vaccinatie wilt verwijderen?")) {
        $.ajax({
          url: "/user/vaccinatie/delete",
          data: {'id' : $(this).data('id') },
          method: 'POST'
        }).done(function() {
          window.location.reload();
        });
      }
    });

    let showOther = () => $('#other').removeClass('hidden')
    let hideOther = () => $('#other').addClass('hidden')

    $('select#infectionDiseaseId').on('change',function(){
        $.ajax({
        url: "/user/vaccinatie/infectie-ziekte/" + $(this).val(),
        method: 'GET'
      }).done(function(response) {
        // hideOther();
        $('select#vaccinationId').html(response.html);
        console.log(response.html);
        
      });
    });

    $('select#vaccinationId').on('change', function(){
      hideOther()
      if($(this).val() === ''){
        showOther()
      }
    })

   


    $(".owl-carousel").owlCarousel({
        items: 3,
        margin: 20,
        loop: true,
        lazyLoad: true,
        nav: false,
        dots: false,
        responsive:{
            0:{
                items:1,
            },
            600:{
                items:3,
            },
            1000:{
                items:4,
            }
        }
    });

    // COOKIES
    setTimeout(function () {
      $("#cookie-consent").fadeIn(200);
    }, 4000);

    $("#close-cookie-consent").click(function() {
      hideCookieConsent();
    }); 

    $('a.cookie-consent-ok').on('click',function(){
      $.ajax({
        url: "/hide-cookie-consent",
        method: 'GET'
      }).done(function(resp) {
        console.log('cookie consent closed', resp);
        hideCookieConsent();
      });
    });
});

function hideCookieConsent(){
  $("#cookie-consent").fadeOut(200, function(){
    $(this).remove();
  });
}


function slugify(text)
{
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}
